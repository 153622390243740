@import '../styles/breakpoints.scss';
@import '../styles/layout-variables.scss';

.AppContents-wrapper {
	min-height: 0;
	flex: 1;
	position: relative;
}

.AppContents {
	height: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: stretch;
	overflow: auto;

	@mixin sidePadding($width) {
		padding-left: $width;
		padding-right: $width;
	}
	@media #{$media-borders} {
		@include sidePadding($proportional-borders-percentage * 1%);
	}
	@media #{$media-full-borders} {
		@include sidePadding($full-borders-width);
	}
	@media #{$media-full-width} {
		@include sidePadding(calc((100% - #{$full-width}) / 2));
	}

	> section {
		background: white;
		padding: $section-padding-vertical $section-padding-horizontal;
		margin-top: $section-margin-top;
		border-radius: .5em;
	}

	&::after { // use this instead of padding that doesn't work in flex
		content: '';
		padding-bottom: 2em;
	}
}

.linkTarget {
	position: relative;
	top: $section-margin-top - $header-height;
}