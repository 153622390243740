.press {
	display: flex;
	flex-flow: row wrap;
	& > * {
		width: 150px;
		margin: 1em 4em 1em 0;
	}
	a {
		white-space: initial;
	}
	img {
		display: block;
		box-shadow: 4px 4px 4px #ccc;
		margin-bottom: 1em;
	}
}

