@import '../styles/colors.scss';

.concert {
	margin: 1em 0 1em 7em;
	position: relative;
	min-height: 6em;

	& .fancyDate {
		position: absolute;
		top: 0;
		left: -7em;
	}

	& > :not(.fancyDate) {
		margin-bottom: .3em;
	}
}

.concert-tag {
	background: $link-color;
	color: white;
	font-weight: bold;
	padding: 0 .25em;
	border-radius: .25em;

	&:not(:first-child) {
		margin-left: 1em;
	}
}

.concert-name {
	font-weight: bold;
	font-size: 120%;
}

.concert-where {
	font-style: italic;
}
