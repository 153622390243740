$border-top-color: #f99;

.fancyDate {
	display: inline-flex;
	flex-direction: column;
	align-items: center;

	border: 1px solid black;
	border-top: 7px solid $border-top-color;
	width: 4em;
	padding: .5em .2em;

	> * {
		line-height: 100%;
	}

	&.fancyDate-notThatFancy {
		border-top-color: desaturate($border-top-color, 100%);
	}
}

.fancyDate-day {
	font-size: 130%;
	font-weight: bold;
}

.fancyDate-month {
	margin-top: 1em;
	font-size: 80%;
	font-weight: bold;
	text-transform: uppercase;
}

.fancyDate-year {
	margin-top: .5em;
	font-size: 80%;
}
