.footer {
	width: 100%;
	flex: 0 0 auto;
}

.cookieConsent {
	text-align: center;
	background: #ddd;
	padding: .3em;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;

	button {
		min-height: 3em;
		padding-left: 1em;
		padding-right: 1em;
		margin: 0 .5em;
	}
}