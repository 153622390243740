@import '../styles/unbounded.scss';
@import '../styles/layout-variables.scss';

$boundForHell-color: #fb3e06;
$min-album-page-size: 15em;
$media-scrollbar-reserve: 1.5em; // reserve for page scrollbar

.boundForHell-wrapper {
	width: 100%;
	padding-top: 200%;
	position: relative;
}

.boundForHell {
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	display: flex;
	flex-flow: column nowrap;
	box-shadow: 5px 5px 3px #eee;

	> * {
		height: 50%;
	}
}

.boundForHell button {
	cursor: pointer;
	outline: none;
}

.boundForHell-content {
	position: relative;
	& > :not(.salt-backCover) {
		z-index: 1;
	}
}
.salt-backCover {
	position: absolute;
	width: 100%;
	height: 100%;
}
.boundForHell-trackList {
	position: absolute;
	left: 19%;
	top: 10%;
	min-height: 50%;
	max-height: 73%;
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	justify-content: space-between;
	font-family: 'Unbounded', sans-serif;
	font-weight: bold;
}

.unstyled-button.boundForHell-track {
	display: block;
	text-align: left;

	color: $boundForHell-color;
	&.boundForHell-bonusTrack {
		color: desaturate($boundForHell-color, 100%);
	}
	&:hover {
		color: saturate($boundForHell-color, 30%);
	}
	&:active {
		transform: scale(.9);
	}

	> span {
		display: block;
	}
}
.boundForHell-firstRegularTrack span:first-child::before,
.boundForHell-lastRegularTrack span:first-child::after {
	content: '';
}

.boundForHell-songTitle {
	text-transform: uppercase;
	font-weight: bold;
}
.boundForHell-songSubtitle {
	font-size: 70%;
}

.boundForHell .icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity .5s ease;

	svg {
		stroke: $boundForHell-color;
		fill: $boundForHell-color;
	}
}
.boundForHell button:hover .icon svg {
	circle {
		fill: $boundForHell-color;
	}
	path {
		fill: white;
		stroke: white;
	}
}

.boundForHell:not(.boundForHell-playing) .icon-pause,
.boundForHell-playing .icon-play {
	opacity: 0;
}

.boundForHell-play,
.boundForHell-download {
	position: absolute;
	.icon svg {
		animation: 3.5s linear infinite anim-boundForHell-button;
	}
}

.boundForHell-play {
	top: 30%;
	left: 61.5%;
	width: 20%;
	height: 20%;
}

.boundForHell-download {
	top: 52.5%;
	left: 64%;
	width: 15%;
	height: 15%;
}
@keyframes anim-boundForHell-button {
	0%, 100% { opacity: 1; }
	45% { opacity: .6; }
	50% { opacity: .8; }
}

.boundForHell-downloadLinks {
	position: absolute;
	text-align: right;
	right: 0;
	top: 110%;
	background: white;

	a {
		display: block;
		border: 1px solid $boundForHell-color;
		border-radius: 3px;
		padding: .25em;
		&:link, &:active, &:visited {
			color: $boundForHell-color;
		}
		&:hover {
			text-decoration: none;
			background: $boundForHell-color;
			color: white;
		}
	}
}

@media (min-width:
	(2 * $min-album-page-size + 2 * $section-padding-horizontal) // section outer dimensions
	* (100 / (100 - 2 * $proportional-borders-percentage)) // add borders
	+ $media-scrollbar-reserve // add reserve
) {
	.boundForHell-wrapper {
		padding-top: 50%;
	}

	.boundForHell {
		flex-flow: row nowrap;
		> * {
			height: 100%;
			width: 50%;
		}
	}
}
