.contact-photos {
	img {
		display: block;
		max-width: 100%;
		position: relative;;
		border: 2px solid white;
		
		&:first-child {
			margin: 0 0 0 auto;
		}

		@media (min-width: 500px) {
			max-width: 70%;
			&:last-child {
				margin-top: -3em;
			}
		}
	}
}
