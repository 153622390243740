.background {
	background-image: url('../assets/bg-0001.jpg?size=480');
	background-repeat: no-repeat;
	background-position: center;
	background-attachment: fixed;
	background-size: cover;
	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: -999;

	@media not screen {
		display: none;
	}

	@media (min-width: 480px), (min-height: 320px) {
		background-image: url('../assets/bg-0001.jpg?size=600');
	}

	@media (min-width: 600px), (min-height: 400px) {
		background-image: url('../assets/bg-0001.jpg?size=1000');
	}

	@media (min-width: 1000px), (min-height: 666px) {
		background-image: url('../assets/bg-0001.jpg?size=1500');
	}

	@media (min-width: 1400px), (min-height: 933px) {
		background-image: url('../assets/bg-0001.jpg?size=3000');
	}
}
