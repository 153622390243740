@import '../styles/colors.scss';
@import '../styles/spinner.scss';

body {
	font-family: 'Titillium Web', sans-serif;
}

html, body, #root {
	padding: 0;
	margin: 0;
	height: 100%;
}

h1, h2, h3 {
	margin: 0;
}

.unstyled-button {
	box-sizing: content-box;
	background: none;
	border: 0;
	padding: 0;
	margin: 0;
	color: inherit;
	font: inherit;
	line-height: normal;
	overflow: visible;
	-webkit-appearance: none;
	-moz-appearance: none;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	&::-moz-focus-inner {
		border: 0;
		padding: 0;
	}
}

a {
	text-decoration: none;
	white-space: nowrap;
	&:link {
		color: $link-color;
	}
	&:visited {
		color: desaturate($link-color, 50%);
	}
	&:hover {
		text-decoration: underline;
	}
	&:active {
		color: saturate($link-color, 30%);
	}
}

.App {
	display: flex;
	flex-flow: column nowrap;

	height: 100%;
	width: 100%;
}
