@import '../styles/breakpoints.scss';
@import '../styles/layout-variables.scss';

$big-logo-size: 10em;
$big-logo-top: -1em;
$visible-padding: .5em;
$margin-top: 2em;
$logo-animation-time: .3s;
$initial-animation-time: 1.5s;

header {
	z-index: 50;
	position: relative;
	top: 0;
	margin-top: $margin-top;
	margin-bottom: 2em;
	flex: 0 0 auto;
	background: black;
	color: white;
	box-sizing: border-box;
	height: $header-height;
	padding: $visible-padding;
	padding-left: $header-height + $visible-padding;
	display: flex;
	flex-flow: column;
	justify-content: center;
	align-items: flex-start;
	line-height: 1.5em;
}

.header-sticky-sentinel {
	position: absolute;;
	background: none;
	left: 0;
	right: 0;
	top: -$margin-top;
	height: $margin-top + $big-logo-top;
}

.header-nav-and-language {
	display: flex;
	flex-flow: row nowrap;
	align-self: stretch;
	align-items: center;
	flex: 1;
}

.language-switcher {
	align-self: flex-start;
	margin-left: 1em;
}

header nav {
	flex: 1;

	ul {
		list-style: none;
		margin: 0;
		padding: 0;
		li {
			display: inline-block;
			&:not(:last-child) {
				margin-right: .5em;
			}
		}
	}

	a {
		display: inline-block;
		text-align: center;

		&::before {
			display: block;
			content: attr(data-text);
			font-weight: bold;
			height: 0;
			overflow: hidden;
			visibility: hidden;
		}

		&:hover {
			font-weight: bold;
			text-decoration: none;
		}

		&:link, &:visited, &:hover, &:active {
			color: white;
		}
	}
}

.header-logo {
	width: $header-height;
	height: $header-height; // for IE11
	position: absolute;
	top: 0;
	left: 0;
}
.header-logo-circle {
	stroke-dasharray: 5000 2520;
	animation: header-logo-circle-animation $initial-animation-time linear forwards;
}
.header-logo-path {
	stroke-dasharray: 10000 100;
	animation: header-logo-path-animation $initial-animation-time linear forwards;
}
@keyframes header-logo-circle-animation {
	0% {
		stroke-dashoffset: 5000;
	}
	100% {
		stroke-dashoffset: 7520;
	}
}
@keyframes header-logo-path-animation {
	0% {
		stroke-dashoffset: 10000;
	}
	99% {
		stroke-dashoffset: 8875;
	}
	100% {
		stroke-dashoffset: 1000;;
	}
}

header h1 {
	text-transform: uppercase;
	font-weight: normal;
	font-size: 1.5em;
}

.header-subtitle {
	font-size: .8em;
	display: flex;
	align-self: stretch;
	&::before, &:after {
		content: '';
		margin: auto 0;
		height: 0;
		border-top: solid white 1.2px;
	}
	&::before {
		width: 2em;
		margin-right: .5em;
	}
	&::after {
		flex: 1;
		margin-left: .5em;
	}
}

// keep this after .header-subtitle
.header-text {
	display: none;
}

@media (min-height: 500px) {
	header {
		position: sticky;
	}
}

@media #{$media-borders} {
	header {
		padding-left: $header-height / 2 + $visible-padding;
	}

	.header-logo {
		left: -$header-height / 2;
	}
}

@media #{$media-full-borders} {
	header {
		line-height: initial;
		justify-content: flex-end;
		transition: padding $logo-animation-time ease;
		.header-nav-and-language {
			justify-content: flex-end;
		}
		nav {
			flex: 0 0 auto;
		}
		.language-switcher {
			position: absolute;
			top: $visible-padding;
			right: $visible-padding;
			margin: 0;
		}
	}
	.header-text {
		display: flex;
	}
	.header-logo {
		transition: all $logo-animation-time ease;
	}

	header:not(.sticky) {
		padding-left: $big-logo-size / 2 + $visible-padding;

		.header-logo {
			width: $big-logo-size;
			height: $big-logo-size; // for IE11
			top: $big-logo-top;
			left: -($big-logo-size / 2);
		}
	}
}
