$cards-padding: 1em;

.photos-collections {
	display: flex;
	flex-flow: row wrap;
}

.photos-collection {
	display: inline-block;
	margin: 1em 3em 0 0;

	&:nth-child(3n+2) .photos-cards {
		transform: rotate(8deg);
	}
	&:nth-child(3n+3) .photos-cards {
		transform: rotate(3deg);
	}
}

.photos-collectionDescription {
	font-style: italic;
	text-align: center;
	margin-bottom: .5em;
}

.photos-cards {
	padding: $cards-padding;
	position: relative;
	z-index: 0;
	transform-origin: center center;
}

.photos-card {
	border: 2px solid #ccc;
		transform-origin: left bottom;

	&:not(:first-child) {
		position: absolute;
		left: $cards-padding;
		bottom: $cards-padding;
		height: calc(100% - #{2 * $cards-padding});
		width: auto;
	}
	&:nth-child(1) {
		transform: rotate(-5deg) translateX(1em);
	}
	&:nth-child(2) {
		z-index: -1;
		transform: rotate(-10deg) translateY(.5em);
	}
	&:nth-child(3) {
		z-index: -2;
		transform: rotate(5deg);
	}
}
