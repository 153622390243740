.language-switcher-link {
  display: inline-block;
  padding: .2em .5em;
  font-weight: bold;
  color: white;
  border: 2px solid white;
  &:visited, &:link, &:hover, &:hover {
    color: white;
    text-decoration: none;
  }
  &.language-switcher-active {
    background: white;
    color: black;
    &:visited, &:link, &:hover, &:hover {
      color: black;
    }
  }
}
