@import '../styles/breakpoints.scss';

$icon-size: 3em;
$padding: .5em;

.photoPopUp {
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: black;
	z-index: 1000;

	display: flex;
	flex-flow: column nowrap;
}

.photoPopUp-iconLink {
	padding: $padding;
}

.photoPopUp-topRow {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;

	z-index: 1;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
}

.photoPopUp-collectionDescription {
	flex: 1 1 auto;
	font-style: italic;
	box-sizing: border-box;
	span {
		padding: $padding;
		background: white;
	}
}

.photoPopUp-content {
	flex: 1 1 auto;
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	position: relative;

	.spinner {
		z-index: -1;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
	}
}

.photoPopUp-scrollLeft,
.photoPopUp-scrollRight {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}
.photoPopUp-scrollLeft {
	left: 0;
}
.photoPopUp-scrollRight {
	right: 0;
}

.photoPopUp-photo {
	flex: 1 1 auto;
	align-self: stretch;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

.photoPopUp-close {
	flex: 0 0 auto;
}

.photoPopUp .thumbnails {
	display: none;
}

@media (min-height: 40em) {
	.photoPopUp .thumbnails {
		display: block;
	}
	.photoPopUp-topRow {
		position: relative;
	}
}

@media #{$media-borders} {
	.photoPopUp-scrollLeft,
	.photoPopUp-scrollRight {
		position: static;
		transform: none;
	}
}
