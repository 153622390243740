.player {
	max-height: 200px;
	background: black;
	color: white;
	transition: max-height .3s ease, padding .3s ease;
	overflow: hidden;
	display: flex;
	flex-flow: row nowrap;
	justify-content: center;
	align-items: center;
	padding: .5em;
}

.player-waveformWrapper {
	flex: 1 1 auto;
	max-width: 55em;
	margin-right: 1em;
}

.player-waveform {
	display: inline-block;
	min-height: 96px;
	max-width: 100%;
	position: relative;;
	cursor: crosshair;

	img {
		max-width: 100%;
		height: 100%;
	}
}

.player-playhead,
.player-seeker {
	position: absolute;
	top: 0;
	width: 1px;
	height: 100%;
	pointer-events: none;
}
.player-playhead {
	background: white;
}
.player-ended .player-playhead {
	display: none;
}
.player-seeker {
	display: none;
	background: #ccc;
}

.player-currentTime,
.player-duration {
	position: absolute;
	bottom: 0;
	background: #00000080;
	padding: 0 .25em;
}
.player-currentTime {
	left: 0;
}
.player-duration {
	right: 0;
}

.player-controls {
	flex: 0 0 auto;
	align-self: stretch;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-between;
}

.player-controlsTopRow {
	display: flex;
	flex-flow: row nowrap;
	justify-content: space-between;
}

.player-songInfo {
	flex: 1 1 auto;
	min-width: 5em;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.player-title {
	font-weight: bold;
}
.player-subtitle {
	font-size: 80%;
}

.player-albumAndTrackNumber {
	font-style: italic;
}

.player-closeButton {
	width: 2em;
	height: 2em;
	position: relative;
	cursor: pointer;
	outline: none;
}

.player-icons {
	display: flex;
	flex-flow: row nowrap;
	align-items: flex-end;

	.unstyled-button, a {
		width: 3em;
		height: 3em;
		position: relative;
		cursor: pointer;
		outline: none;
		&:not(:last-child) {
			margin-right: .5em;
		}
	}

	svg {
		stroke: white;
		fill: white;
	}
}

.player .icon {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	transition: opacity .3s ease;
}
.player-paused .icon-pause {
	opacity: 0;
}
.player:not(.player-paused) .icon-play {
	opacity: 0;
}
.player .icon-rewind,
.player .icon-forward {
	transform: scale(.8);
}

