$border: .5em;

.thumbnails {
	position: relative;
}

.thumbnails-scrollarea {
	overflow: auto;
	scroll-behavior: smooth;
	display: flex;
	flex-flow: row nowrap;
	position: relative; // need offsetLeft on children
	background: #ccc;
}

.thumbnail {
	flex: 0 0 auto;
	height: 100px;
	padding: $border $border/2;
	position: relative;
	display: flex;
	align-items: center;
	&:first-child {
		padding-left: $border
	}
	&:last-child {
		padding-right: $border;
	}
	img {
		max-height: 100%;
	}
}

.thumbnail-scroller.unstyled-button {
	position: absolute;
	top: 0;
	width: 50px;
	height: 100%;
	z-index: 1;
	background: linear-gradient(to right, #ffff, #fffc 10%, #fff3);
	outline: none;
	cursor: pointer;
	transition: opacity .3s ease, fill .3s ease;
	transition-delay: 0s;
}
.thumbnail-scroller.thumbnail-scroller-hidden {
	opacity: 0;
	visibility: hidden;
	transition: opacity .3s ease, fill .3s ease, visibility .3s linear .3s;
}
.thumbnail-scroller-left {
	left: 0;
}
.thumbnail-scroller-right {
	right: 0;
	transform: scaleX(-1);
}
