@mixin circle() {
	content: '';
	display: block;
	width: 0;
	height: 0;
	border-radius: 50%;
	position: absolute;
	left: -1em;
	top: -1em;
}

.spinner {
	width: 0;
	height: 0;
	position: relative;
}
.spinner::before {
  @include circle();
  border: 1em solid black;
  animation: anim-spinner1 3s infinite;
}
.spinner::after {
  @include circle();
  border: 1em solid white;
  animation: anim-spinner2 .5s ease alternate infinite;
}
.spinner-black::after {
	z-index: -1;
}
@keyframes anim-spinner1 {
  0% {
    transform: rotate(0);
  }
  50% {
    transform: translateY(-10%) rotate(180deg);
  }
  100% {
    transform: translateY(10%) rotate(360deg);
  }
}
@keyframes anim-spinner2 {
  0% {
    transform: translateX(-20%);
  }
  50% {
    transform: scale(50%);
  }
  100% {
    transform: translateX(20%);
  }
}
