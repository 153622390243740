$transition-time: .2s;

.icon {
	width: 50px;
	height: 50px;
	svg circle {
		transition: fill $transition-time ease;
	}
	svg path {
		transition: fill $transition-time ease, stroke $transition-time ease;
	}
}
.icon-black {
	svg {
		circle {
			fill: #888;
		}
		path {
			stroke: white;
		}
	}
}

a:hover, button:hover {
	.icon svg circle {
		fill: black;
	}
	.icon-black svg {
		circle {
			fill: white;
		}
		path {
			stroke: black;
		}
	}
}

a:active, button:active {
	.icon svg {
		transform: scale(.9);
	}
}
