.showMore-link {
	display: block;
}

.showMore {
	visibility: hidden;
	opacity: 0;
	max-height: 0;
	transition: opacity 1s, max-height 1s, visibility 1s linear 1s;

	&.showMore-visible {
		opacity: 1;
		max-height: 1000em;
		visibility: visible;
		transition: opacity 1s, max-height 1s, visibility 0s;
	}
}
